<template>
  <!--
    The view for the LicenceReport-component
  -->
  <Portlet
    :title="$t('softwareComponent.licencesReport')"
    icon="id-card-alt"
    class="licenceReportView"
  >
    <LicenceReport />
  </Portlet>
</template>

<script>
export default {
  name: "LicenceReportView",
  components: {
    LicenceReport: () => import('@/components/SoftwareComponent/LicenceReport.vue')
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.licencesReport')
    }
  }
}
</script>
